<template>
  <div class="ground-content">
    <div
      class="
        consume-bar
        margin-top-bottom-twenty
        box-shadow-light-grey
        border-radius-6
        padding-right-left-5
      "
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class=""
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/groundPage/quckApp/quckAppSet"
          @click.native="handleTabClick('/groundPage/quckApp/quckAppSet')"
          >快应用设置</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/groundPage/quckApp/callBackData"
          @click.native="handleTabClick('/groundPage/quckApp/callBackData')"
          >回传数据</el-menu-item
        >
      </el-menu>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
    };
  },
  watch: {
    $route(newVal) {
      this.activeSubMenu = newVal.path;
      this.tabName = newVal.path;
    },
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  methods: {
    handleTabClick(tab) {
      this.tabName = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.consume-bar {
  background: #fff;
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
}
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
.ground-content {
  height: 100%;
  .operation {
    background: #fff;
    box-sizing: border-box;
    // padding:  0 20px;
  }
  .ground-table {
    background: #fff;
  }
}
</style>